import React from 'react';

type BreadcrumbSectionProps = {
  title: JSX.Element | string;
  paragraph?: JSX.Element | string;
};

const BreadcrumbSection = ({ title, paragraph }: BreadcrumbSectionProps) => (
  <div className="breadcrum-area breadcrumb-banner">
    <div className="container">
      <div className="section-heading">
        <h1 className="title h2">{title}</h1>
        {paragraph && <p>{paragraph}</p>}
      </div>
    </div>
  </div>
);

export default BreadcrumbSection;
