import React from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const TermUse = () => {
  return (
    <Layout>
      <Seo title="Terms and Conditions of Use | Banco" description="" />
      <main className="main-wrapper">
        <HeaderCommon />

        <BreadcrumbSection
          title="Terms and Conditions of Use"
          paragraph={<span style={{ width: '100%' }}>Last updated on 29 July 2021.</span>}
        />

        {/* Privacy Policy */}
        <div className="section section-privacy">
          <div className="container">
            <aside>
              <ul>
                <li>
                  <a className="listHeader" href="#01">
                    01. User Agreement
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#02">
                    02. Changes{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#03">
                    03. Additional Terms & Policies{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#04">
                    04. Account & User Details{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#05">
                    05. User Restrictions{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#06">
                    06. User Verification & Checks{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#07">
                    07. Ownership, Access Rights & Usage Restrictions{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#08">
                    08. Provision Against Prohibited Activities{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#09">
                    09. Termination/Suspension of Usage Rights{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#10">
                    10. User Content{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#11">
                    11. Third Party Services{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#12">
                    12. User Disputes{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#13">
                    13. PROVISION AGAINST WARRANTY{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#14">
                    14. LIABILITY LIMITATION & REMEDY{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#15">
                    15. Indemnification{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#16">
                    16. Legal Claims{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#17">
                    17. International Usage{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#18">
                    18. Electronic Communications{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#19">
                    19. Miscellaneous{' '}
                  </a>
                </li>
              </ul>
            </aside>
            <article>
              <div>
                <h4 className="articleHeader">
                  <span id="01">01.</span>
                  User Agreement
                </h4>
                <p className="paragraph">
                  You are advised to read all terms and conditions of use (“Terms of Use”) carefully
                  as they govern the usage of our Platforms which comprises of{' '}
                  <a href="https://www.rootant.com/">https://www.rootant.com/</a>,{' '}
                  <a href="https://www.banco.asia/">https://www.banco.asia/</a>,{' '}
                  <a href="https://www.banco.com.sg/">https://www.banco.com.sg/</a>,{' '}
                  <a href="https://www.bancr.sg/">https://www.bancr.sg/</a>,{' '}
                  <a href="https://www.rabc.sg/">https://www.rabc.sg/</a> and their related
                  sub-domains, together with all intellectual property contained therein, and any
                  related applications (including mobile applications) and includes their respective
                  internet-based services, features, content, and functionality (the “Service” or
                  “Services”).
                </p>
                <p className="paragraph">
                  TKNEST PTE. LTD., (a wholly owned subsidiary of RootAnt Global Pte Ltd), a private
                  limited company incorporated under the laws of Singapore (“Company”), is the owner
                  and operator of the Platform. The Company may, at its sole discretion, and at any
                  time, discontinue this Platform, in whole or in part, or may prevent or restrict
                  your use of the Platform or any Service, in each case with or without notice to
                  you and for any reason, including, but not limited to, for violation of these
                  Terms of Use.
                </p>
                <p className="paragraph">
                  By using the Service(s) on the Platform or by visiting the Platform, you confirm
                  that you accept and agree to be legally bound by these Terms of Use which
                  incorporates by reference our Privacy Policy, and any other guidelines we may
                  include at a later date with reasonably notice given. If you do not agree , please
                  do not access or use the Platform or the Services.
                </p>
                <p className="paragraph">
                  The terms "we," "us," "our," refer to the Company and its affiliates collectively.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="02">02.</span>
                  Changes
                </h4>
                <p className="paragraph">
                  We reserve the right, at our sole discretion, to modify or replace the Terms of
                  Use, or change, suspend or discontinue any aspect of the Platform and the Services
                  at any time. Where appropriate, we may notify of any changes taking effect,
                  including by posting the revised version of these Terms of Use on the Platform or
                  by email. What constitutes a material change will be determined at our sole
                  discretion. If you disagree or do not wish to be bound by such change, you may
                  choose to terminate your account and discontinue use of the Services before the
                  changes become effective. You agree that your continued use of the Services after
                  changes to these Terms of Use becomes effective constitutes your acceptance of
                  such changes.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="03">03.</span>
                  Additional Terms & Policies
                </h4>
                <p className="paragraph">
                  Please review our Privacy Policy, incorporated herein by reference, for
                  information and notices concerning our collection and use of your personal
                  information. Certain areas of our Services on the Platform may have different
                  terms and conditions posted or may require you to agree with and accept additional
                  terms and conditions or policies. If there is a conflict between the Terms of Use
                  and terms and conditions or policies posted for a specific Service, the latter
                  shall take precedence with respect to your use of that specific Service.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="04">04.</span>
                  Account & User Details
                </h4>
                <p className="paragraph">
                  <b>4.1</b> In order to use our Services, you must be a registered account holder.
                  As part of our client on-boarding process, you will need to accept these Terms of
                  Use. During registration, you will agree to provide us with complete, truthful and
                  accurate information. We may ask you at any time to confirm the accuracy of your
                  information and/or provide additional supporting documents. You must update this
                  information whenever it changes. We cannot be responsible for any financial loss
                  arising out of your failure to do so. You shall bear any losses that occur due to
                  your submission of invalid, incorrect or inaccurate information.
                </p>
                <p className="paragraph">
                  <b>4.2</b> Any actions taken, transactions, commitments made through an Account
                  will be held against the user of the account, and we will not be held responsible
                  for any mistakes or illegal usage of an account.
                </p>
                <p className="paragraph">
                  <b>4.3</b> If you suspect your account, login details, password or other security
                  feature is stolen, lost, used without authorisation or otherwise compromised, you
                  are advised to change your password. We rely on users to inform us immediately in
                  order to help them take the appropriate remedies and mitigate damage in the event
                  of unauthorized usage of their accounts.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="05">05.</span>
                  User Restrictions
                </h4>
                <p className="paragraph">
                  <b>5.1</b> This Platform is targeted at users aged 18 and above. This is due to
                  the need for compliance with applicable laws and to process financial transactions
                  through the Platform. By accessing the Platform, you affirm that you are more than
                  18 years of age and are fully able and competent to enter into the terms,
                  conditions, obligations, affirmations, representations, and warranties set forth
                  in these Terms of Use, and to abide by and comply with these Terms of Use. We may
                  ask you at any time to show proof of your age. If parents/guardians find any
                  unsuitable usage by your child/ward, you can email us at{' '}
                  <a href="mailto:support@banco.com.sg">support@banco.com.sg</a> to request a
                  removal of the account. Any under-age users are not protected by our Terms of Use
                  and the Company nor would any of its affiliates, directors, agents, employees or
                  partners be liable or held responsible for their actions.
                </p>
                <p className="paragraph">
                  <b>5.2</b> If you are not a consumer, you confirm that you have authority to bind
                  any business or entity on whose behalf you use our Services, and that business or
                  entity accepts these terms.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="06">06.</span>
                  User Verification & Checks
                </h4>
                <p className="paragraph">
                  In accordance with applicable laws, users and/or their customers may be subject to
                  necessary security and customer due diligence checks in connection with their use
                  of the Services, including without limitation, a verification of identity and
                  criminal background checks. You hereby give your consent for us to perform such
                  verification and background checks in the manner and frequency as we deem
                  reasonably necessary so long as such is performed in compliance with applicable
                  law. Furthermore, any illegal activities will render your privacy protections
                  invalid and we will submit to the relevant authorities such information required
                  by them for compliance with applicable law.
                </p>
                <p className="paragraph">
                  Even though we may conduct such checks, we cannot unequivocally confirm the
                  identity of each user. As such, we do not assume any liability for the accuracy or
                  reliability of such checks or any information provided through the Services.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="07">07.</span>
                  Ownership, Access Rights & Usage Restrictions
                </h4>
                <p className="paragraph">
                  The Platform and the Services and all intellectual property rights contained
                  therein are owned by us, our licensors or both (as applicable). Intellectual
                  property rights include rights such as: copyright, trademarks, domain names,
                  design rights, database rights, patents and all other intellectual property rights
                  of any kind whether or not they are registered or unregistered (anywhere in the
                  world). We and/or our licensors reserve all of our and their rights in any
                  intellectual property in connection with the Platform and the Services. You
                  acknowledge such ownership of the intellectual property rights and will not take
                  any action to jeopardize, limit or interfere in any manner with our and our
                  licensors' ownership the intellectual property rights to the Platform and the
                  Services.
                </p>
                <p className="paragraph">
                  Subject to your compliance with these Terms of Use, we hereby grant you a limited,
                  non-exclusive, non-sub-licensable, revocable, non-transferrable license to: (i)
                  access and use the Platform solely in connection with your use of the Services;
                  and (ii) access and use content, information and related materials that may be
                  made available through the Services, in each case solely for your use. Any rights
                  not expressly granted herein are reserved by us and our licensors.
                </p>
                <p className="paragraph">
                  Nothing in these Terms of Use grants you any legal rights in the Platform and the
                  Services other than as necessary to enable you to access the Platform and the
                  Services. You agree not to circumvent or delete any notices contained therein
                  (including any intellectual property notices) and in particular in any digital
                  rights or other security technology embedded or contained therein.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="08">08.</span>
                  Provision Against Prohibited Activities
                </h4>
                <p className="paragraph">
                  You agree not to use the Platform or Services other than for purposes related to
                  application(s) for loan(s) or funding from participating financial institutions.
                  Specifically, you agree not to (i) violate or infringe upon the rights of others
                  in any way, including without limitation rights of privacy, publicity and
                  intellectual property, (ii) violate or facilitate the violation of any law,
                  regulation or ordinance or to encourage others to violate any of the same, (iii)
                  send unsolicited communications of any type or nature, including without
                  limitation to contact users or others for employment, contracting or any purpose
                  not related to the use of the Services as set forth herein, (iv) transmit, relay,
                  or otherwise communicate vulgar, unlawful, obscene, profane, tortious, hateful,
                  libellous, indecent or otherwise offensive or objectionable materials or
                  information, (v) falsify your identity or impersonate another person; or (vi)
                  engage in conduct that limits the use and enjoyment of the Services, or any part
                  thereof, by other users in any way, in each case as determined by us in our sole
                  and absolute discretion.
                </p>
                <p className="paragraph">
                  In addition, you agree not to (1) reverse engineer, disassemble, alter, decompile,
                  duplicate, create derivative works from, make copies of, extract information from,
                  distribute, license, lease, sell, resell, transfer, publicly display, publicly
                  perform, transmit, stream, broadcast or otherwise exploit the Platform or
                  Services, in whole or in part, except as expressly permitted by us; (2) post any
                  software, links or content that directly or indirectly contains viruses, corrupted
                  files or any other similar mechanism that may adversely affect the operation of
                  the Platform or its Services; (3) connect, use, attempt to connect or use in any
                  way the Services, for purposes other than your private, personal use as explicitly
                  offered on the Platform; (4) link to, mirror or frame any portion of the Platform
                  except as expressly permitted by us; (5) incorporate, integrate or otherwise
                  include any portion of the Services that is comprised of software into any
                  software, program or product that communicates, accesses, or otherwise connects
                  with the Services; (6) cause or launch any programs or scripts for the purpose of
                  scraping, indexing, surveying, or otherwise data mining any portion of the
                  Platform or Services or unduly burdening or hindering the operation and/or
                  functionality of any aspect of the Platform or Services; (7) attempt to gain
                  unauthorized access to or impair any aspect of the Platform or Services or its
                  related systems or networks; or (8) remove any copyright, trademark or other
                  proprietary notices from any portion of the Platform or Services. Finally, you may
                  not authorize or assist any third party to do any of the things described in this
                  Section. By breaching this provision, you may be guilty of a criminal offence
                  under the Computer Misuse and Cybersecurity Act (Cap. 50A) of Singapore. We will
                  report any such breach to the relevant government authorities and/or law
                  enforcement agencies and co-operate with such authorities/agencies by disclosing
                  your identity to them. In the event of such breach, your right to use our Services
                  will cease immediately.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="09">09.</span>
                  Termination/Suspension of Usage Rights
                </h4>
                <p className="paragraph">
                  These Terms of Use remain in effect until your use of the Service is terminated by
                  us. We may terminate or suspend your access to the Platform and/or use of the
                  Service for any reason at any time, and with or without notice. Upon such
                  termination or suspension, your right to use the Platform and its Services will
                  immediately cease.
                </p>
                <p className="paragraph">
                  If you violate these Terms of Use in any way, we shall have the right, in our sole
                  discretion, to terminate or suspend your account, remove or modify any
                  account-related content or access, or take any other action that we believe is
                  appropriate. We may investigate violations of these Terms of Use and may involve
                  and cooperate with law enforcement authorities in taking legal action against
                  users who are involved in such violations, including without limitation pursuing
                  civil, criminal and injunctive redress.
                </p>
                <p className="paragraph">
                  You hereby waive and hold us (and our officers, directors, employees, partners,
                  agents and related entities) harmless from any claims resulting from any action we
                  take during or as a result of our investigation and from any actions taken as a
                  consequence of such investigations by us or any law enforcement authorities.
                </p>
                <p className="paragraph">
                  You agree not to register under a different username or identity after your
                  account has been suspended or terminated, including in the name of a third party
                  even if you are acting on behalf of such third party. These Terms of Use shall
                  remain enforceable against you even after the termination or suspension of your
                  account.
                </p>
                <p className="paragraph">
                  You may terminate these Terms of Use at any time by ceasing your use of the
                  Platform and its Services. All sections of these Terms of Use which by their
                  nature should survive the expiration or termination of these Terms of Use shall
                  continue in full force and effect subsequent to and notwithstanding the expiration
                  or termination of these Terms of Use.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="10">10.</span>
                  User Content
                </h4>
                <p className="paragraph">
                  We may, in our sole discretion, permit you to post, upload, publish, submit or
                  transmit content on the Platform (“User Content”). By making available any User
                  Content on or through the Platform, you hereby grant to us a worldwide,
                  irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with
                  the right to sublicense, to use, copy, adapt, modify, distribute, license, sell,
                  transfer, publicly display, publicly perform, transmit, stream, broadcast, access,
                  view, and otherwise exploit such User Content on, through, by means of or to
                  promote, market or advertise the Platform or Service, or for any other purpose in
                  our sole discretion, except that private messaging through the Platform will not
                  be used by us in public advertising or marketing material. For the avoidance of
                  doubt, the license granted to us shall survive termination of the Platform or your
                  account. With the exception of your feedback or comments, we do not claim
                  ownership rights in your User Content.
                </p>
                <p className="paragraph">
                  You acknowledge and agree that you are solely responsible for all User Content
                  that you make available on or through the Platform. Accordingly, you represent and
                  warrant that: (a) you either are the sole and exclusive owner of all User Content
                  that you make available on or through the Platform or you have all rights,
                  licenses, consents and releases that are necessary to grant to us the rights in
                  such User Content, as contemplated under these Terms of Use; and (b) neither the
                  User Content nor your posting, uploading, publication, submission or transmittal
                  of the User Content or our use of the User Content (or any portion thereof) on,
                  through or by means of the Platform will infringe, misappropriate or violate a
                  third party’s patent, copyright, trademark, trade secret, moral rights or other
                  proprietary or intellectual property rights, or rights of publicity or privacy, or
                  result in the violation of any applicable law or regulation. You agree that we may
                  proofread, summarize or otherwise edit and/or withdraw User Content provided by
                  you, and you understand it remains your sole responsibility to monitor your User
                  Content and ensure that such edited User Content is accurate and consistent with
                  your representations and warranties in these Terms of Use.
                </p>
                <p className="paragraph">
                  The following types of content are not allowed on our Platform:
                </p>
                <ul>
                  <li className="paragraph">
                    Content that is unlawful, threatening, abusive, libellous, pornographic,
                    obscene, vulgar, indecent, offensive or which infringes on the intellectual
                    property rights or other rights of any third party; and
                  </li>
                  <li className="paragraph">
                    Content that contains any viruses and/or other code that has contaminating or
                    destructive elements.
                  </li>
                </ul>
                <p className="paragraph">
                  We reserve the right to define the above guidelines and add more restrictions. You
                  are encouraged to use their common sense and discretion while following the above
                  guidelines. We also hold no responsibility for user actions or content and hold
                  the right to remove any User Content which violates our guidelines.
                </p>
                <p className="paragraph">
                  If you believe that any content on the Platform violates these Terms of Use,
                  please email to <a href="mailto:support@banco.com.sg">support@banco.com.sg</a>
                </p>
                <p className="paragraph">
                  We are not obliged to maintain, keep or provide a copy of any User Content
                  provided by you or others, otherwise than for compliance with applicable laws and
                  our Privacy Policy. If you wish to be provided a copy of your User Content, and we
                  are agreeable to providing such a copy, you agree that we have the sole discretion
                  to charge an administration fee.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="11">11.</span>
                  Third Party Services
                </h4>
                <p className="paragraph">
                  Our Services may contain links to participating financial institutions and/or
                  third¬-party web sites or services that are not owned or controlled by us. We have
                  no control over, and assume no responsibility for, the content, privacy policies,
                  or practices of any participating financial institution or third-party web sites
                  or services. You further acknowledge and agree that we shall not be responsible or
                  liable, directly or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with use of or reliance on any such content, goods or
                  services available on or through any such web sites or services.
                </p>
                <p className="paragraph">
                  You are responsible for deciding if you wish to access the websites of such
                  participating financial institutions or third parties by clicking on a link or
                  installing an application. The inclusion of any link or application on the
                  Platform does not imply that we endorse the linked site or application. Your use
                  of the links and access to the websites of such participating financial
                  institutions or third-parties shall be at your own risk and you agree that your
                  use of an application or third-party website via the Platform is on an “as is” and
                  “as available” basis without any warranty for any purpose.
                </p>
                <p className="paragraph">
                  Additional terms and conditions of participating financial institutions or
                  third-party service providers may apply to your transactions. Please read these
                  additional terms and conditions carefully. You acknowledge and accept that a
                  participating financial institution and/or third-party service provider may
                  change, modify or discontinue, temporarily or permanently, any services provided
                  to you without notice to you and we shall not be liable or responsible for such
                  change, modification, suspension and/or discontinuance of services.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="12">12.</span>
                  User Disputes
                </h4>
                <p className="paragraph">
                  We reserve the right, but do not have the obligation, to monitor or take any
                  action we deem appropriate regarding disputes between you and any participating
                  financial institutions and/or service providers. We urge parties to cooperate with
                  each other to promptly resolve any such disputes. Should we be forced to step in,
                  all parties agree to accept our decision as final and binding.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="13">13.</span>
                  PROVISION AGAINST WARRANTY
                </h4>
                <p className="paragraph">
                  <b>
                    The Services and Platform are provided “as is,” “with all faults” and “as
                    available,” and the entire risk as to satisfactory quality, performance,
                    accuracy, security, and effort lies with you. To the maximum extent permitted by
                    applicable law, neither we nor our officers, directors, employees, partners,
                    agents, and related entities make any representations, warranties or conditions,
                    express or implied about our Services or the Platform. We disclaim any and all
                    warranties or conditions, express, statutory and implied, including without
                    limitation (i) warranties or conditions of merchantability, fitness for a
                    particular purpose, workmanlike effort, accuracy, title and non-infringement,
                    (ii) warranties or conditions arising through course of dealing or usage of
                    trade, and (iii) warranties or conditions that access to or use of the Platform
                    and/or the Services will be uninterrupted or error-free.
                  </b>
                </p>
                <p className="paragraph">
                  <b>
                    We do not warrant that the Platform or Services will operate error-free or that
                    the Platform or its server are free of computer viruses or other harmful
                    mechanisms. You understand and agree that you download or otherwise obtain
                    material or data through the use of the Platform or Services at your own
                    discretion and risk and that you will be solely responsible for any damage to
                    your computer system or loss of data that results from the download of such
                    material or data. If your use of the Platform or the Services results in the
                    need for servicing or replacement of equipment or data, we are not responsible
                    for those costs.
                  </b>
                </p>
                <p className="paragraph">
                  <b>
                    We cannot and do not warrant that your materials, including any personal or
                    financial information, supplied by you will not be intercepted, deleted,
                    misappropriated or used by others.
                  </b>
                </p>
                <p className="paragraph">
                  <b>
                    We do not warrant any recommend or endorse any particular participating
                    financial institution or service provider. While we shall use all reasonable
                    efforts to have your application processed expeditiously, you acknowledge that
                    we have no control over the processes and decisions of the participating
                    financial institutions and cannot be responsible for any rejection or delays in
                    loan approval or closing or for any other matters beyond our reasonable control.
                    For the avoidance of doubt, in no event will we be liable for the online or
                    offline conduct of a user and you hereby release us from any liability relating
                    to such. If a dispute arises between you and one or more participating financial
                    institutions and/or service providers or users, you shall release us from any
                    and all claims, demands, and damages of every kind and nature, known and
                    unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or
                    in any way connected with such disputes.
                  </b>
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="14">14.</span>
                  LIABILITY LIMITATION & REMEDY
                </h4>
                <p className="paragraph">
                  You acknowledge and agree that we are only willing to provide you access to the
                  Platform and provide the Services if you agree to certain limitation of our
                  liability to you and to third parties. To the extent allowed by applicable law, in
                  no event will we be liable for any damages, including without limitation any
                  direct, indirect, consequential, special, incidental, or punitive damages arising
                  out of, based on, or resulting from these Terms of Use or your use of the Services
                  or the Platform, even if we have been advised of the possibility of such damages.
                  The exclusion of damages under this section is independent of any rights or
                  remedies available to you and shall survive in the event such rights or remedies
                  fail of its essential purpose or is otherwise deemed unenforceable. These
                  limitations and exclusions apply without regard to whether the damages arise from
                  (i) breach of contract, (ii) breach of warranty, (iii) negligence, or (iv) any
                  other cause of action, to the extent such exclusion and limitations are not
                  prohibited by applicable law. If you are dissatisfied with the Services, or you do
                  not agree with any part of these Terms of Use, or you have any other dispute or
                  claim with or against us with respect to these Terms of Use or the Services, then
                  your sole and exclusive remedy is to discontinue using the Services and the
                  Platform.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="15">15.</span>
                  Indemnification
                </h4>
                <p className="paragraph">
                  To the fullest extent permitted by applicable law, you agree to release,
                  indemnify, defend and hold harmless the Company, its parent, subsidiaries and
                  affiliates, and its and their respective shareholders, officers, directors,
                  employees, agents and advisors, from and against any and all losses, liabilities,
                  claims (including claims without legal merit or brought in bad faith), demands,
                  damages, costs or expenses, causes of action, suits, proceedings, judgments,
                  awards, executions and liens, including reasonable attorneys’ fees and costs
                  (whether brought by third parties or otherwise) (collectively, “Claims“) due to or
                  arising out of any materials, information or User Content that you submit, post,
                  transmit, modify or otherwise make available through the Services and the
                  Platform, your use of the Platform or the Services, your connection to the
                  Platform or the Services, any breach by you of these Terms of Use, your violation
                  of any rights of another person or user and/or any fraud by you. We reserve the
                  right, at our own expense, to assume the exclusive defense and control of any
                  matter subject to indemnification by you, which will not excuse your indemnity
                  obligations under this Section. This provision shall continue to be in force even
                  after you cease using our Services.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="16">16.</span>
                  Legal Claims
                </h4>
                <p className="paragraph">
                  In the interest of resolving all legal claims expeditiously, you hereby agree that
                  all legal claims must be filed within six (6) months after the cause of action has
                  occurred failing which the claims shall be deemed to be extinguished and we shall
                  not be held liable.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="17">17.</span>
                  International Usage
                </h4>
                <p className="paragraph">
                  The Platform and the Services are directed to residents of Singapore. Those who
                  choose to access the Platform or use the Services from outside of Singapore do so
                  at their own risk and may be subject to securities and tax regulations within
                  their applicable jurisdictions that are not addressed on this Platform. You agree
                  to comply with all local rules regarding online conduct, including all laws,
                  rules, codes and regulations of the country in which you reside and the country
                  from which you access the Platform. In addition, you agree to comply with all
                  applicable laws, rules, codes and regulations regarding the transmission of
                  technical data exported from Singapore.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="18">18.</span>
                  Electronic Communications
                </h4>
                <p className="paragraph">
                  The very nature of the Services provides communications between you and us by any
                  electronic means (e.g., via the Platform, email, text message). For purposes of
                  forming a legally binding agreement, you consent to receive communications from us
                  in electronic form and agree that all terms and conditions, agreement, notices,
                  disclosures and other communications that we provide to you electronically satisfy
                  any applicable legal requirements, including that these be made in writing. You
                  acknowledge that there is inherent risk in use of the Internet and that
                  information transmitted through the Internet in general is not confidential. We
                  cannot and do not guarantee the privacy or protection of any electronic
                  communications through the Internet.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="19">19.</span>
                  Miscellaneous
                </h4>
                <p className="paragraph">
                  <b>19.1</b> Our failure to exercise or enforce any right or provision of the Terms
                  of Use shall not constitute a waiver of such right or provision. If any provision
                  of the Terms of Use is held by a court or other tribunal of competent jurisdiction
                  to be invalid, illegal or unenforceable for any reason, such provision shall be
                  eliminated or limited to the minimum extent such that the remaining provisions of
                  the Terms of Use will continue in full force and effect.
                </p>
                <p className="paragraph">
                  <b>19.2</b> The Terms of Use and any other terms and conditions of service on the
                  Platform constitute the entire agreement between you and us with respect to the
                  Platform and Services and supersedes all prior understandings, agreements,
                  representations and warranties, both written and oral, between us with respect to
                  the Platform and Services. These Terms of Use may be prospectively modified by us,
                  by posting a revised Terms of Use on the Platform.
                </p>
                <p className="paragraph">
                  <b>19.3</b> We shall have no liability to you for any breach of these Terms of Use
                  caused by any event or circumstance beyond our reasonable control including,
                  without limitation, epidemics, pandemics, strikes, lock-outs or other industrial
                  disputes; breakdown of systems or network access; or flood, fire, explosion or
                  accident.
                </p>
                <p className="paragraph">
                  <b>19.4</b> A person who is not a party to these Terms of Use shall have no right
                  under the Contracts (Rights of Third Parties) Act (Chapter 35B) to enforce any of
                  its terms, regardless of whether such person or entity has been identified by
                  name, as a member of a class or as answering a particular description.
                </p>
                <p className="paragraph">
                  <b>19.5</b> The Terms of Use, and any dispute or claim arising out of or in
                  connection with it and/or your account, or its subject matter or formation
                  (including non-contractual disputes or claims) shall be governed by and construed
                  in accordance with the laws of Singapore. Any disputes or claim arising out of or
                  in connection with these Terms of Use and/or your account shall be subject to the
                  exclusive jurisdiction of the Courts of Singapore.
                </p>
                <p className="paragraph">
                  <b>19.6</b> These Terms of Use, and your rights and obligations hereunder, may not
                  be assigned, subcontracted, delegated or otherwise transferred by you without our
                  prior written consent, and any attempted assignment, subcontract, delegation, or
                  transfer in violation of the foregoing will be null and void.
                </p>
              </div>
            </article>
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
};

export default TermUse;
